module.exports = {
  siteTitle: 'Treaty of Eagles',
  siteDescription: 'Native Casino Gaming',
  siteKeywords: 'software engineer, Iowa',
  siteUrl: 'https://kordellschrock.io',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-283238183-1',
  googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk',
  name: 'Kordell Schrock',
  location: 'Tama, IA',
  email: 'kordell.schrock@treatyofeagles.com',
  github: 'https://github.com/kschrock',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/kschrock',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/k-schrock',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/kordell_schrock',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Team',
      url: '/#team',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
