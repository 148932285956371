import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  IconGitHub,
  IconLinkedin,
  IconCodepen,
  IconInstagram,
  IconTwitter,
  IconStar,
  IconFork,
} from '@components/icons';
import { socialMedia } from '@config';
import { navLinks, navHeight } from '@config';
import styled from 'styled-components';
import { theme, mixins, media } from '@styles';
const { colors, fontSizes, fonts } = theme;
import './footer.css'; // Import the CSS file
import { IconLogo } from '@components/icons';
import logo from '../images/logo.png'
const StyledContainer = styled.footer`
  ${mixins.flexCenter};
  flex-direction: column;
  padding: 15px;
  background-color: ${colors.black};
  color: ${colors.slate};
  text-align: center;
  height: auto;
  min-height: 70px;
`;

const StyledList = styled.ol`
list-style: none;
padding: 0;
margin: 0;
text-align: center;

@media (max-width: 767px) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  place-items: center; /* Center items vertically and horizontally */
  
  li {
    margin-top: 10px;
    text-align: center; /* Center the content of each grid item */
  }

}
`;

const StyledListLink = styled(Link)`
text-align: center; /* Center the content of StyledListLink */
`;

const Footer = () => {
  const [githubInfo, setGitHubInfo] = useState({
    stars: null,
    forks: null,
  });

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'production') {
  //     return;
  //   }
  //   fetch('https://api.github.com/repos/bchiang7/v4')
  //     .then(response => response.json())
  //     .then(json => {
  //       const { stargazers_count, forks_count } = json;
  //       setGitHubInfo({
  //         stars: stargazers_count,
  //         forks: forks_count,
  //       });
  //     })
  //     .catch(e => console.error(e));
  // }, []);
  
  function Copyright() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth <= 767);
      }
  
      // Add event listener for resize events
      window.addEventListener('resize', handleResize);
  
      // Initial check for mobile screen size
      handleResize();
  
      // Cleanup the event listener
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures that this effect only runs once after the initial render
  
    return (
      <p variant="body1" align="center" component="div">
        {"© "}{currentYear}{" Treaty of Eagles. "}
        {isMobile && <hr />} {/* Conditionally render <hr> for mobile screens */}
        All rights reserved.
      </p>
    );
  }
  
  return (
    <StyledContainer>
      <div className="footer">
        <div className="container">
        <div className="footer-logo">
                  <a href="/" aria-label="home">
                  {/* <IconLogo style={{ height: '40px' }} /> */}
                  <img src={logo} alt="Logo" className="footer-logo-img" />
                  </a>
            </div>
          {/* <div className="footer-content"> */}
            {/* <div className="footer-links"> */}
            <div className="containerLinks" style={{ textAlign: 'center' }}>
    <StyledList className="footer-menu">
      <li style={{  padding: '20px', borderRadius: '5px' }} key={'Footer-homelink'}>
        <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home</a>
      </li>
      {navLinks.map(({ url, name }, i) => (
        <li key={i}>
          <StyledListLink to={url} style={{ padding: '20px', borderRadius: '5px', textDecoration: 'none', color: 'inherit' }}>
            {name}
          </StyledListLink>
        </li>
      ))}
    </StyledList>
  </div>
            {/* </div> */}
            {/* <div class="footer-social">
              <ul class="social-icons">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
              </ul>
            </div> */}
          {/* </div> */}
          <div className="footer-bottom">
            <Copyright />
          </div>
        </div>
      </div>
      {/* <StyledMetadata tabindex="-1">
        <StyledGitHubLink
          href="https://github.com/kschrock"
          target="_blank"
          rel="nofollow noopener noreferrer">
          <div>Built with ❤️...</div>

        </StyledGitHubLink>
      </StyledMetadata> */}
    </StyledContainer>
  );
};

Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;
