import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      {/* This is for the 'B' group */}
      {/* <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M21.5 21 H30 V40 L50 21 L60 21 L35 45 L60 69 L50 69 L30 50 L30 69 L21.5 69 L21.5 21"
          fill="#FEE715FF"
        />
      </g> */}

      {/* background circle white */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 38.909 45.098 m -49.2896 0 a 50 49.2896 90 1 0 98.5792 0 a 50 49.2896 90 1 0 -98.5792 0"
          fill="#ffffff"
        />
      </g>
       {/* This is for the left wing LOWER */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 12 52 L 12 33 L 33 33"
          fill="#febc11"
        />
      </g>

      {/* This is for the right wing LOWER */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 68 52 L 68 33 L 47 33"
          fill="#febc11"
        />
      </g>

      {/* This is for the left wing */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 14 48 L 14 29 L 34 29"
          fill="#000000"
        />
      </g>

       {/* This is for the right wing */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 66 48 L 66 29 L 46 29"
          fill="#000000"
        />
      </g>

       {/* This is for the center oval */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 40 26 A 0.75 0.9375 0 1 0 40 30.25 A 0.75 0.9375 0 1 0 40 26 Z"
          fill="#000000"
        />
      </g>

      {/* This is for the center dimond background */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 40 55 L 48 45 L 40 35 L 32 45 Z"
          fill="#a7a9ac"
        />
      </g>

       {/* This is for the center dimond */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 40 53 L 47 44 L 40 35 L 33 44 Z"
          fill="#000000"
        />
      </g>

       {/* This is for the left tail top */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 28.6 58.91 L 25 54 L 28.6 49.09"
          fill="#000000"
        />
      </g>
       {/* This is for the right tail top */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 51.4 58.91 L 55 54 L 51.4 49.09"
          fill="#000000"
        />
      </g>

       {/* This is for the left tail middle */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 25.6 62.51 L 22 58 L 25.6 52.79"
          fill="#ed1d24"
        />
      </g>

      {/* This is for the right tail middle */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 54.4 62.51 L 58 58 L 54.4 52.79"
          fill="#ed1d24"
        />
      </g>

       {/* This is for the left tail bottom */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 22.8 66.51 L 19.2 62 L 22.8 56.79"
          fill="#a7a9ac"
        />
      </g>

       {/* This is for the right tail bottom */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 57.2 66.51 L 60.8 62 L 57.2 56.79"
          fill="#a7a9ac"
        />
      </g>

    {/* This is for the left leg bottom */}
    <g transform="translate(11.000000, 5.000000)">
            <path
              d="M 38.9 73 L 35.18 68.4 L 38.9 68.4"
              fill="#febc11"
            />
          </g>

      {/* This is for the right leg bottom */}
    <g transform="translate(11.000000, 5.000000)">
            <path
              d="M 41.1 73 L 44.82 68.4 L 41.1 68.4"
              fill="#febc11"
            />
          </g>

      {/* This is for the left leg middle */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 39 70 L 35.18 65.4 L 39 65.4"
          fill="#ed1d24"
        />
      </g>

      {/* This is for the right leg middle */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 41 70 L 44.82 65.4 L 41 65.4"
          fill="#ed1d24"
        />
      </g>

      {/* This is for the left leg top */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 39 66 L 35.18 61.4 L 39 61.4"
          fill="#000000"
        />
      </g>

      {/* This is for the right leg top */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 41 66 L 44.82 61.4 L 41 61.4"
          fill="#000000"
        />
      </g>

      {/* This is for the bottom feet */}
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 45 80 H 35 V 82.5 H 45 V 80"
          fill="#000000"
        />
      </g>

       {/* This is for the neck of eagle */}
       {/* <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 40 16 L 44 24 L 36 24 Z"
          fill="#000000"
        />
      </g> */}

      {/* This is for the head of eagle */}
      {/* <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 40 16 L 34.031 16.443 L 38 20 Z"
          fill="#000000"
        />
      </g> */}

      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 40 16 L 34.031 16.443 L 38 20 Z M 40 16 L 44 24 L 36 24"
          fill="#000000"
        />
      </g>

       {/* This is for the eye of eagle */}
       <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 37.743 16.486 L 37.605 16.997 L 36.606 17.139 L 36.256 16.743 Z"
          fill="#ffffff"
        />
      </g>

      {/* This is for the simplified path */}
      <g transform="">
  {/* top right */}
  <path
    stroke="#000000"
    // strokeWidth="1"
    fill="#ffffff"
    d="M100,50
    C100,22.3858 77.6142,0 50,0
    L55,5
    C80,6 95,30 95,45
    L100,50 Z"
  />
</g>
<g transform="">
  {/* top left */}
  <path
    stroke="#000000"
    fill="#000000"
    // strokeWidth="1"
    d="m 0 50 C 0 22.3858 22.3858 0 50 0 L 45 5 c -25 1 -40 25 -40 40 L 5 45 Z"
  />
</g>
      <g transform="">
        {/* bottom left */}
        <path
          stroke="#000000"
          fill="#ed1d24"
          // strokeWidth="1"
          d="M0,50
          C0,77.6142 22.3858,100 50,100
          L45,95
          C25,94 8,70 5,55
          L0,50 Z"
        />
      </g>
      <g transform="">
  {/* bottom right */}
  <path
    stroke="#000000"
    fill="#febc11"
    // strokeWidth="1"
    d="M100,50
    C100,77.6142 77.6142,100 50,100
    L55,95
    C75,94 92,70 95,55
    L100,50 Z"
  />
</g>
      
    </g>
  </svg>
);

export default IconLoader;
