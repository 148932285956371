import React from 'react';
import PropTypes from 'prop-types';
import { socialMedia } from '@config';
import { Side } from '@components';
import { FormattedIcon } from '@components/icons';
import styled from 'styled-components';
import { theme } from '@styles';
const { colors } = theme;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 90px;
    margin: 0 auto;
    background-color: ${colors.lightSlate};
  }

  li:last-of-type {
    margin-bottom: 20px;
  }
`;
const StyledLink = styled.a`
  padding: 10px;
  &:hover,
  &:focus {
    transform: translateY(-3px);
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;
const StyledTriangles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const TriangleSVG = styled.svg`
  width: 10vw;
  height: 80vh;
  margin: 0 10px;
`;

const Triangle = ({ color, points }) => (
  <TriangleSVG xmlns="http://www.w3.org/2000/svg">
    <polygon points={points} fill={color} />
  </TriangleSVG>
);
const Social = ({ isHome }) => (
  <Side isHome={isHome} orientation="left">
  <svg width="80vh" height="80vh" xmlns="http://www.w3.org/2000/svg">
    {/* <rect width="100%" height="100%" fill="blue" /> */}
    <g>
    {/* <path d="M 20 0 L 1 37 L 39 37 Z" fill="black" /> */}
    <path d="M 33.5 0 L 16.5 0 L 16.5 3 L 33.5 3 Z" fill="#ed1d24" />
<path d="M 22.5 0 L 27.5 0 L 27.5 140 L 22.5 140 Z" fill="#ed1d24" />
    <path d="M 33.5 120 L 16.5 120 L 16.5 123 L 33.5 123 Z" fill="#ed1d24" />
    <path d="M 16.5 21 L 33.5 21 L 33.5 16 L 16.5 16 Z" fill="#ed1d24" />
<path d="M 22.5 24 L 27.5 24 L 27.5 37 L 22.5 37 Z" fill="#ed1d24" />
<path d="M 6 67 L 2 63 L 2 86 L 7 80 L 43 80 L 48 86 L 48 63 L 44 67 Z" fill="white" />
<path d="M 5.5 50 L 11.5 56 L 11.5 36 L 22.5 47 L 27.5 47 L 38.5 36 L 38.5 56 L 44.5 50 L 44.5 98 L 38.5 89 L 38.5 108 L 27.5 97 L 22.5 97 L 11.5 108 L 11.5 89 L 5.5 98 Z" fill="#8892AF" />
<path d="M 16.5 25 L 22.5 31 L 27.5 31 L 33.5 25 L 33.5 70 L 25 62 L 16.5 70 Z" fill="white" />
<path d="M 16.5 115 L 22.5 108 L 27.5 108 L 33.5 115 L 33.5 45 L 24.5 45 L 16.5 45 Z" fill="white" />
<path d="M 25 60 L 37.5 73 L 25 85 L 12.5 73 Z" fill="white" />
<path d="M 25 63 L 34.5 73 L 25 82 L 15.5 73 Z" fill="black" />
<path d="M 16 133 L 24 140 L 26 140 L 34 133 L 34 140 L 25 146 L 16 140 Z" fill="white" />
<path d="M 34 133.75 L 39 129.25 L 39 160 L 42 157 L 42 165 L 34 173 Z" fill="#febc11" />
<path d="M 39 198 L 39 160 L 48 151 L 48 189 Z" fill="##8892AF" />
<path d="M 16 133.75 L 11 129.25 L 11 160 L 8 157 L 8 165 L 16 173 Z" fill="#febc11" />
<path d="M 11 198 L 11 160 L 2 151 L 2 189 Z" fill="##8892AF" />

<path d="M 7 268 L 42 268 L 47 264 L 47 294 L 50 290 L 50 322 L 47 318 L 47 350 L 41 344 L 9 344 L 3 350 L 3 318 L 0 322 L 0 290 L 3 294 L 3 264 Z" fill="#ed1d24" />
<path d="M 12.5 256 L 17.5 261 L 32.5 261 L 37.5 256 L 37.5 264 L 43.5 260 L 43.5 353 L 37.5 349 L 37.5 357 L 32.5 352 L 17.5 352 L 12.5 357 L 12.5 349 L 6.5 353 L 6.5 260 L 12.5 264 Z" fill="black" />
<path d="M 13 281 L 18 284 L 18 270 L 32 270 L 32 284 L 37 281 L 37 297 L 42 293 L 42 318 L 37 314 L 37 330 L 32 327 L 32 341 L 18 341 L 18 327 L 13 330 L 13 314 L 8 318 L 8 293 L 13 297 Z" fill="#8892AF" />


<path d="M 26 202 L 29 197 L 29 171 C 28.6667 162 21 162 20 171 L 20 197 L 23 202 L 23 212 L 17 209 L 17 269 L 23 278 L 23 294 L 13 305 L 23 315 L 23 331 L 17 342 L 17 402 L 23 399 L 23 409 L 20 414 C 20 449.3333 20 449.3333 20 433.6667 C 20 449.3333 29 449.3333 29 433.6667 L 29 414 L 26 409 L 26 399 L 32 402 L 32 342 L 26 331 L 26 315 L 36 305 L 26 294 L 26 278 L 32 269 L 32 209 L 26 212 Z" fill="white" />
<path d="M 24.5 299 L 30 305 L 24.5 311 L 19 305 Z" fill="#febc11" />

<path d="M 33.5 610 L 16.5 610 L 16.5 613 L 33.5 613 Z" fill="#ed1d24" />
<path d="M 22.5 610 L 27.5 610 L 27.5 465 L 22.5 465 Z" fill="#ed1d24" />
<path d="M 33.5 490 L 16.5 490 L 16.5 493 L 33.5 493 Z" fill="#ed1d24" />
<path d="M 16.5 589 L 33.5 589 L 33.5 594 L 16.5 594 Z" fill="#ed1d24" />
<path d="M 22.5 586 L 27.5 586 L 27.5 573 L 22.5 573 Z" fill="#ed1d24" />
<path d="M 6 543 L 2 547 L 2 524 L 7 530 L 43 530 L 48 524 L 48 547 L 44 543 Z" fill="#ed1d24" />
<path d="M 5.5 560 L 11.5 554 L 11.5 574 L 22.5 563 L 27.5 563 L 38.5 574 L 38.5 554 L 44.5 560 L 44.5 512 L 38.5 521 L 38.5 502 L 27.5 513 L 22.5 513 L 11.5 502 L 11.5 521 L 5.5 512 Z" fill="#8892AF" />
<path d="M 16.5 585 L 22.5 579 L 27.5 579 L 33.5 585 L 33.5 540 L 25 548 L 16.5 540 Z" fill="white" />
<path d="M 16.5 495 L 22.5 502 L 27.5 502 L 33.5 495 L 33.5 565 L 24.5 565 L 16.5 565 Z" fill="white" />
<path d="M 25 550 L 37.5 537 L 25 525 L 12.5 537 Z" fill="white" />
<path d="M 25 547 L 34.5 537 L 25 528 L 15.5 537 Z" fill="black" />
<path d="M 16 476 L 24 469 L 26 469 L 34 476 L 34 469 L 25 463 L 16 469 Z" fill="white" />
<path d="M 34 476.25 L 39 480.75 L 39 450 L 42 453 L 42 445 L 34 437 Z" fill="#febc11" />
<path d="M 39 411 L 39 449 L 48 458 L 48 420 Z" fill="#8892AF" />
<path d="M 16 476.25 L 11 480.75 L 11 450 L 8 453 L 8 445 L 16 437 Z" fill="#febc11" />
<path d="M 11 411 L 11 449 L 2 458 L 2 420 Z" fill="#8892AF" />










  </g>
  </svg>
    {/* <StyledList>
      {socialMedia &&
        socialMedia.map(({ url, name }, i) => (
          <li key={i}>
            <StyledLink
              href={url}
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label={name}>
              <FormattedIcon name={name} />
            </StyledLink>
          </li>
        ))}
    </StyledList> */}
  </Side>
);

Social.propTypes = {
  isHome: PropTypes.bool,
};

export default Social;
